<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Affiliation
            <v-spacer></v-spacer>
            <add-button
              permission="grade-create"
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              "
              >New Affiliation
            </add-button>
          </v-card-title>

          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="form.items.data"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
            :loading="form.fetchingData"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-left text-bold">
                  <strong>{{ item.name }}</strong>
                </td>
                <td class="text-center">
                  <v-chip
                    small
                    label
                    outlined
                    v-if="item.status === 1"
                    color="success"
                  >
                    Active</v-chip
                  >
                  <v-chip
                    small
                    label
                    outlined
                    v-if="item.status === 0"
                    color="error"
                  >
                    Inactive</v-chip
                  >
                </td>
                <td class="text-right" style="display: flex; align-items: center;">
                  <view-button 
                    permission="section-read"
                    @click.native="faculty(item.id)"
                  />

                  <edit-button
                    v-if="item.allowed"
                    permission="grade-update"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    v-if="item.allowed"
                    permission="grade-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="400px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-form
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
            >
              <v-text-field
                autocomplete="off"
                label="Name*"
                required
                class="pa-0"
                outlined
                dense
                v-model="form.name"
                name="name"
                :error-messages="form.errors.get('name')"
                :rules="[(v) => !!v || 'Name is required']"
              />

              <v-select
                :items="statuses"
                required
                class="pa-0"
                label="Status*"
                outlined
                dense
                v-model="form.status"
                :rules="[(v) => !!v || 'Status is required']"
                :error-messages="form.errors.get('status')"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn
              :loading="form.loading"
              color="success"
              text
              @click="validate"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
	mixins: [Mixins],
	data: () => ({
		valid: true,
		lazy: false,
		isLoading: false,
		// rowsPerPageItems: { "items-per-page-options": [5, 10, 25, 50, 75] },

		form: new Form(
			{
				name: "",
				status: "",
				type: "affiliation",
			},
			"api/affiliation-faculty"
		),
		headers: [
			{ text: "#", align: "left", value: "id", width: 50, sortable: false },
			{ text: "Name", align: "left", value: "name", sortable: false },
			{ text: "Status", align: "center", value: "rank", width: 110 },
			{ text: "Action", align: "right", sortable: false, width: 200 },
		],
		statuses: [
			{
				text: "Active",
				value: "1",
			},
			{
				text: "Inactive",
				value: "0",
			},
		],
	}),
	mounted() {
   
	},
	computed: {},

	watch: {
		pagination: function () {
			this.get();
		},
	},
	methods: {
		validate() {
			if (this.$refs.form.validate()) {
				this.save();
			}
		},
		get(params) {
			let extraParams = "type=" + "affiliation";
			let query = [null, undefined].includes(params)
				? this.queryString(extraParams)
				: params;
			this.form.get(null, query).then(({ data }) => {
				this.pagination.totalItems = data.meta.total;
			});
		},
		faculty(id) {
			this.$router.push({
				name: "faculty",
				params: {
					affiliationId: id,
				},
			});
		},

		save() {
			this.form.store();
		},
	},
};
</script>
<style lang="scss" scoped></style>
